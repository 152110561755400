import { Container, Row, Col } from "react-bootstrap";

export default function CareersScreen() {
  return (
    <Container>
      <h2>Careers</h2>
      <hr
        style={{
          background: "#FF0000",
          height: "3px",
          border: "#FF0000",
        }}
      />
      <Row>
        <Col className="col-xs-12 col-sm-12">
          <div class="container">
            <div class="center wow fadeInDown">
              <p class="lead1"></p>
              <p align="left">We are always looking out for talented, motivated and pationate professionals. Please send in your CV with a brief background to <a href="mailto:info@voltapowersource.com">info@voltapowersource.com</a>. </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}