import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

export default function ClientsCarousel() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 3 // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      // autoPlay={this.props.deviceType !== "mobile" ? true : false}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      // removeArrowOnDeviceType={["tablet", "mobile"]}
      // deviceType={this.props.deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      <div>
        <a href="https://www.tatacommunications.com/" target='_blank' rel="noreferrer">
          <img decoding="async" src="../images/clients/tata_comm.png" alt="" width="200px" height="100px" />
        </a>
      </div>
      <div>
        <a href="https://indianrailways.gov.in" target='_blank' rel="noreferrer">
          <img decoding="async" src="../images/clients/indian_railways.png" alt="" width="100px" height="100px" />
        </a>
      </div>
      <div>
        <a href="https://www.ctrls.in" target='_blank' rel="noreferrer">
          <img decoding="async" src="../images/clients/ctrls.png" alt="" width="200px" height="100px" />
        </a>
      </div>
      <div>
        <a href="https://www.tataprojects.com/" target='_blank' rel="noreferrer">
          <img decoding="async" src="../images/clients/tata_prj.jpg" alt="" width="180px" height="100px" />
        </a>
      </div>
      <div>
        <a href="https://www.bharatpetroleum.in" target='_blank' rel="noreferrer">
          <img decoding="async" src="../images/clients/bpcl.png" alt="" width="70px" height="100px" />
        </a>
      </div>
      <div>
        <a href="https://beml.co.in" target='_blank' rel="noreferrer">
          <img decoding="async" src="../images/clients/beml.png" alt="" width="140px" height="100px" />
        </a>
      </div>
      <div>
        <a href="https://www.cdac.in/" target='_blank' rel="noreferrer">
          <img decoding="async" src="../images/clients/cdac.jpg" alt="" />
        </a>
      </div>
    </Carousel>
  );
}