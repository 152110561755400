import { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';

export default function NavMenu() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const submitHandler = (e) => {
    e.preventDefault();
    navigate(query ? `/search/?query=${query}` : '/search');
  };

  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-auto" fixed='top'>
          <Container fluid>
            <Navbar.Brand href="/">
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="90.000000pt" height="37.000000pt" viewBox="0 0 135.000000 56.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,56.000000) scale(0.100000,-0.100000)"
                  fill="#000000" stroke="none">
                  <path fill="#565656" opacity="1.000000" stroke="#FFFFFF"
                    d="M352 508 c-15 -15 -16 -42 -1 -32 6 3 29 1 50 -6 44 -15 69 -3 69 34 0 17 -2 18 -10 6 -7 -12 -14 -12 -43 -2 -42 15 -50 15 -65 0z" />
                  <path fill="#FF0000" opacity="1.000000" stroke="none"
                    d="M0 432 c0 -4 4 -12 9 -17 5 -6 26 -70 47 -142 37 -130 38 -133 65 -133 24 0 30 6 39 38 10 32 8 50 -15 130 -19 68 -24 96 -16 106 6 7 8 16 4 19 -9 10 -133 9 -133 -1z" />
                  <path fill="#FF0000" opacity="1.000000" stroke="none"
                    d="M170 430 c0 -5 7 -13 16 -18 15 -8 15 -13 0 -65 -18 -63 -20 -77 -7 -77 5 0 15 26 22 58 7 31 23 67 35 79 12 12 19 24 16 27 -9 10 -82 7 -82 -4z" />
                  <path fill="#565656" opacity="1.000000" stroke="#FFFFFF"
                    d="M334 421 c-39 -23 -59 -72 -58 -136 1 -64 26 -111 69 -132 36 -17 58 -12 37 9 -15 15 -17 244 -2 253 14 9 12 25 -2 25 -7 0 -27 -9 -44 -19z" />
                  <path fill="#565656" opacity="1.000000" stroke="#FFFFFF"
                    d="M426 423 c14 -29 17 -229 4 -253 -6 -12 -9 -24 -6 -27 12 -12 67 21 86 52 27 44 28 148 1 189 -30 46 -104 79 -85 39z" />
                  <path fill="#FF0000" opacity="1.000000" stroke="none"
                    d="M570 431 c0 -6 5 -13 10 -16 6 -4 10 -58 10 -131 0 -76 -4 -124 -10 -124 -5 0 -10 -4 -10 -10 0 -6 27 -10 60 -10 l60 0 0 134 c0 79 4 137 10 141 26 16 4 25 -60 25 -38 0 -70 -4 -70 -9z" />
                  <path fill="#FF0000" opacity="1.000000" stroke="none"
                    d="M800 389 c0 -36 4 -49 13 -47 6 3 13 14 15 26 2 11 10 26 18 32 24 21 17 40 -16 40 -29 0 -30 -1 -30 -51z" />
                  <path fill="#FF0000" opacity="1.000000" stroke="none"
                    d="M880 311 c0 -102 -3 -133 -15 -145 -8 -8 -15 -17 -15 -20 0 -10 138 -7 144 3 3 5 -1 11 -9 15 -12 4 -15 30 -15 141 l0 135 -45 0 -45 0 0 -129z" />
                  <path fill="#FF0000" opacity="1.000000" stroke="none"
                    d="M990 426 c0 -7 6 -19 14 -26 8 -6 16 -20 18 -31 6 -39 23 -23 26 24 3 46 3 47 -27 47 -20 0 -31 -5 -31 -14z" />
                  <path fill="#FF0000" opacity="1.000000" stroke="none"
                    d="M1166 431 c-3 -5 3 -47 14 -93 25 -103 25 -98 -5 -98 -16 0 -25 -6 -25 -15 0 -10 10 -15 29 -15 20 0 30 -6 34 -20 3 -11 1 -23 -4 -26 -24 -15 -3 -24 55 -24 58 0 94 12 65 22 -6 2 -25 64 -43 138 l-31 135 -42 3 c-23 2 -44 -1 -47 -7z" />
                  <path fill="#FF0000" opacity="1.000000" stroke="none"
                    d="M1121 270 c-15 -57 -30 -96 -42 -104 -25 -17 -14 -26 33 -26 41 0 47 6 21 25 -15 11 -14 18 5 92 24 86 26 103 14 103 -4 0 -18 -41 -31 -90z" />
                  <path fill="#FF0000" opacity="1.000000" stroke="none"
                    d="M760 210 c-7 -17 -21 -35 -31 -41 -31 -16 -22 -29 21 -29 l40 0 0 50 c0 57 -12 64 -30 20z" />
                </g>
              </svg>
              <small>Charge to Energize the World</small>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href='/'>Home</Nav.Link>
                  {/* <NavDropdown
                    title="About Us"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action2">Overview</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">Vision and Mission</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action4">Certification and Awards</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="Services"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Design</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">Installation</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="Products"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action2">Distribution and Control Relay Panels</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">Server Racks</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">Wire Mesh Cable Trays</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action4">Batteries</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">Solar Panels</NavDropdown.Item>
                  </NavDropdown> */}
                  <Nav.Link href='/clients'>Clients</Nav.Link>
                  <Nav.Link href='/careers'>Careers</Nav.Link>
                  <Nav.Link href='/contact'>Contact</Nav.Link>
                </Nav>
                <Form className="d-flex" onSubmit={submitHandler}>
                  <InputGroup>
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                      onChange={(e) => setQuery(e.target.value)}
                      aria-describedby="button-search"
                    />
                    <Button variant="outline-primary" id='button-search'>Search</Button>
                  </InputGroup>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}