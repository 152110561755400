import Carousel from 'react-bootstrap/Carousel';
import { Container } from 'react-bootstrap';
import ClientsCarousel from './ClientsCarousel';

function HomeScreen() {
  return (
    <>
      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block w-100" style={{ height: '93vh' }}
            src="../images/slider/commercial.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Commercial</h3>
            <p>We handle projects that involve designing, installing, and maintaining
              the operation of a building's heating, ventilation, air conditioning,
              electrical, and plumbing systems for a variety of commercial buildings
              such as offices, retail spaces, restaurants, and hotels.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100" style={{ height: '93vh' }}
            src="../images/slider/industrial.jpg"
            alt="Second slide"
          />

          <Carousel.Caption>
            <h3>Industrial</h3>
            <p>We handle projects focused on industrial applications involving the design,
              fabrication, construction, installation, and maintenance of mechanical,
              electrical, and plumbing systems in industrial facilities such as manufacturing
              plants, refineries, power plants, and chemical processing plants. We handle
              complex projects that require close collaboration between engineers, contractors,
              and project managers to ensure that the systems are designed and installed to
              meet the specific needs of the facility.</p>
            <p>Additionally, we are very congnizant of these projects having tight schedules
              and budgets, requiring close collaboration between all parties involved. We
              ensure that the projects are completed on time and within budget.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100" style={{ height: '93vh' }}
            src="../images/slider/manufacturing.jpg"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Manufacturing</h3>
            <p>We are involved in the fabrication of huge structures, containers, and backup
              battery pods right from the design, construction, and assembly of large-scale,
              custom-made components for a variety of applications. We source the specialized
              knowledge and expertise in engineering, welding, and other metalworking techniques,
              as well as advanced equipment and machinery.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <br></br>
      <Container>
        <div className='text-center'>
          <h2 className='text-danger'>Volta Power Source delivers cost effective solutions with good ROI</h2>
          <p>We specialize in fabrication, civil works, electrical, mechanical, and plumbing works.
            We provides comprehensive solutions for construction projects of various types and scales.</p>
          <p><strong>In fabrication</strong>, we design and produce custom-made components or structures for use in
            construction, such as steel frames, metal sheets, and precast concrete elements.</p>
          <p><strong>In civil works</strong>, we undertake site preparation, excavation, grading, and paving
            work, as well as building roads, bridges, and other infrastructure projects.</p>
          <p><strong>In electrical</strong>, we handle the installation and maintenance of electrical systems,
            including wiring, lighting, power distribution, and control systems, as well as providing
            energy-efficient solutions.</p>
          <p><strong>In mechanical</strong>, we install and maintain heating, ventilation, and air conditioning (HVAC)
            systems, as well as plumbing and fire protection systems.</p>
          <p><strong>In plumbing</strong>, we design, install and maintain plumbing systems including water supply,
            drainage and sewage systems, and fixtures.</p>
          <p>We employ a team of skilled and experienced professionals, including engineers,
            architects, project managers, technicians, and tradespeople who work collaboratively to deliver
            high-quality results. We work with a wide range of clients, including government agencies,
            commercial and industrial enterprises.</p>
          <p>Overall, our business provides a comprehensive range of services that enable clients to
            achieve their construction goals, from concept to completion, while ensuring safety, quality, and
            compliance with regulatory standards.</p>
        </div>
      </Container>
      <br></br>
      <div className='text-center'>
        <h2 className='text-danger'>Our Clients</h2>
      </div>
      <ClientsCarousel />
    </>
  );
}

export default HomeScreen;