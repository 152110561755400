import { BrowserRouter, Route, Routes } from 'react-router-dom';

import NavMenu from "./screens/NavMenu";
import HomeScreen from "./screens/HomeScreen";
import FooterMenu from "./screens/FooterMenu";
import OverviewScreen from "./screens/OverviewScreen";
import ClientsScreen from './screens/ClientsScreen';
import CareersScreen from './screens/CareersScreen';
import ContactScreen from './screens/ContactScreen';

function App() {
  return (
    <>
      <BrowserRouter>
        <header>
          <NavMenu />
        </header>
        <main>
          <Routes>
            <Route path='/' element={<HomeScreen />} />
            <Route path='/overview' element={<OverviewScreen />} />
            <Route path='/clients' element={<ClientsScreen />} />
            <Route path='/careers' element={<CareersScreen />} />
            <Route path='/contact' element={<ContactScreen />} />
          </Routes>
        </main>
        <footer>
          <FooterMenu />
        </footer>
      </BrowserRouter>
    </>

  );
}

export default App;
