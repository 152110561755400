import { Container, Row, Col } from "react-bootstrap";

export default function ContactScreen() {
  return (
    <>
      <section id="contact-info">
        <Container>
          <h2>How to Reach Us?</h2>
          <hr
            style={{
              background: "#FF0000",
              height: "3px",
              border: "#FF0000",
            }}
          />
          <div className="gmap-area">
            <div className="container">
              <Row>
                <Col>
                  <address>
                    <h4>Registered office </h4>
                    <h5>VOLTA POWER SOURCE PVT LTD</h5>
                    <p>No. 12, VISHAL MANOR, 2nd Floor, MLA Layout.<br></br>
                      4th Block, RMV 2nd Stage, New BEL Road,<br></br>
                      Bengaluru - 560094,<br></br>
                      Karnataka, India</p>
                    <p>Telfax :+91-80-50573377<br></br>
                      Email : <a href="mailto:info@voltapowersource.com">info@voltapowersource.com</a></p>
                  </address>
                  <p>&nbsp;</p>
                  <address>
                    <h4>Branch office </h4>
                    <h5>VOLTA POWER SOURCE PVT LTD</h5>
                    <p>"Volta Power House"<br></br>
                      Railway Layout, Vijayanagar II Stage,<br></br>
                      Mysuru - 570016,<br></br>
                      Karnataka, India</p>
                    <p>Telfax :+91-80-50573377<br></br>
                      Email :<a href="mailto:info@voltapowersource.com">info@voltapowersource.com</a></p>
                  </address>
                </Col>
                <Col>
                  <ul className="row">
                    <li className="col-sm-6" style={{ listStyleType: 'none' }}>
                      <iframe title='address' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.943615885987!2d77.564287!3d13.039261000000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17e229cdc9ff%3A0xd1d160df09186e98!2sVolta+Power+Source!5e0!3m2!1sen!2sin!4v1434781300965' width="600" height="450" frameborder="0" style={{ border: '0' }} />
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section id="contact-page">
        <Container>
          <div className="center"><h2>Drop Your Message</h2></div>
          <Row className="row contact-wrap">
            <div className="status alert alert-success" style={{ display: "none" }}></div>
            <form className="form-light mt-20" action="contact.php" method="post" name="form1">
              <Col className="col-sm-5 col-sm-offset-1">
                <div className="form-group">
                  <label>Name *</label>
                  <input type="text" name="Name" className="form-control" required="required" />
                </div>
                <div className="form-group">
                  <label>Email *</label>
                  <input type="email" name="Email" className="form-control" required="required" />
                </div>
                <div className="form-group">
                  <label>Phone</label>
                  <input name="Mobile" type="number" className="form-control" required="required" />
                </div>
                <div className="form-group">
                  <label>Company Name</label>
                  <input type="text" className="form-control" name="Company_Name" />
                </div>
              </Col>
              <Col className="col-sm-5">
                <div className="form-group">
                  <label>Subject *</label>
                  <input type="text" name="Subject" className="form-control" required="required" />
                </div>
                <div className="form-group">
                  <label>Message *</label>
                  <textarea name="Requirement" id="Requirement" required="required" className="form-control" rows="8"></textarea>
                </div>
                <br></br>
                <div className="form-group">
                  <button type="submit" name="submit" className="btn btn-primary btn-lg" required="required">Submit Message</button>
                </div>
              </Col>
            </form>
          </Row>
        </Container>
      </section>
    </>
  );
}