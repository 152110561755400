import { Container, Row, Col } from "react-bootstrap";

export default function ClientsScreen() {
  return (
    <Container>
      <h2>Clients</h2>
      <hr
        style={{
          background: "#FF0000",
          height: "3px",
          border: "#FF0000",
        }}
      />
      <Row>
        <Col>
          <div class="center wow fadeInDown">
            <p align="left" class="lead1"></p>
            <p align="left">We provide high quality  solutions to customers across a vast spectrum of the industry. With over a decade of experience and stringent quality standards to go with, it is but natural that we have developed a tradition of high-tech engineering, quality manufacturing, and very competitive pricing, making us a frontrunner in the industry today.</p>
            <p align="left">The blend of excellent manpower and the technology employed translates into an perfect solution for all our clients needs, that is unmatched in the industry. The right resources are optimised to create the best solution for you and at a very competitive pricing.</p>
            <p align="left">&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}