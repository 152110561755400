import { Container } from "react-bootstrap";

export default function FooterMenu() {
  // componentDidMount() {
  //   var aScript = document.createElement('script');
  //   aScript.type = 'text/javascript';
  //   aScript.src = "https//dunsregistered.dnb.com";

  //   document.head.appendChild(aScript);
  //   aScript.onload = function () {
  //     document.getElementById(" /*DIV ID HERE*/ ").InnerHTML = /*YOUR CODE*/;
  //   };
  // }

  return (
    <Container>
      <div className="text-center">
        <small>VPSPL © 2023
          <i className="text-danger"> ||</i> ISO 9001:2015
          <i className="text-danger"> ||</i> ISO 14001:2015
          <i className="text-danger"> ||</i> ISO 45001:2018
          <i className="text-danger"> ||</i> dunsregistered
          <i className="text-danger"> ||</i>
        </small>
      </div>
    </Container >
  );
}