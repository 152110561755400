import { Container } from "react-bootstrap";

export default function OverviewScreen() {
  return (
    <Container>
      <div class="heading_or_title">About Volta Power Source</div>
      <div class="center wow fadeInDown">
        <p class="lead1">Volta Power  was incorporated in 2014 by a team of technocrats with a cumulative experience of over 50 years in the domain of Electrical Consultancy. Volta Power  provides Industrial and Commercial Installations, Maintenance and Service for a broad range of public and private sector clients. Our services range from general lighting and power through to major switchboard and electrical upgrades, in addition to communications, data and advanced integrated installations.</p>
        <p class="lead1">Volta Power  is  involved in Design, Manufacturing, Testing and Supply of Electrical Control Panels, Power Distribution Boards, Motor Control Centers, Bus ducts, Cable Trays, Bus Trucking Systems and Electrical Poles for Street & Industrial Lighting.</p>
        <p class="lead1">Extensive Knowledge of the industry enables us to deliver customized solutions for all our clients. The team at Volta Power is flexible to adapt to varying needs of its clients . Ever since its inception, Volta Power  has executed project ranging from simple to complex projects with great success.
          <br></br>
          Volta Power will strive to be a preferred supplier of electrical equipment by consistent focus on technology, quality and superior customer response and an excellent project management consultant by virtue of the vast man power expertise in various realms of electrical engineering. Successful execution of several projects has helped company gain in confidene to undertake bigger projects. Volta Power is flexible to adapt to varying needs of its customers . Process driven approach and knowledge retention are the key to successful execution of projects.The testimony to this is the repeatability of business over a period of time.  </p>
        <p>&nbsp;</p>
        <p class="lead1"><b>Volta Power  can provide you with consultancy services in the following areas: </b></p>
        <table border="0" width="100%" cellspacing="1" cellpadding="6" align="center" bgcolor="#CECECE" class="lead1">
          <tbody>
            <tr>
              <td valign="middle" bgcolor="#EAEAEA" width="249" height="40"> Feasibility Study</td>
              <td valign="middle" bgcolor="#EAEAEA" width="368" height="40"> Co-ordination</td>
              <td valign="middle" bgcolor="#EAEAEA" width="301" height="40"> Project Electrification</td>
            </tr>
            <tr>
              <td valign="middle" bgcolor="#FFFFFF" width="249" height="40"> Plant Layout Engineering</td>
              <td valign="middle" bgcolor="#FFFFFF" width="368" height="40"> Project Management</td>
              <td valign="middle" bgcolor="#FFFFFF" width="301" height="40"> Automation &amp; Controls</td>
            </tr>
            <tr>
              <td valign="middle" bgcolor="#EAEAEA" width="249" height="40"> Detail Engineering</td>
              <td valign="middle" bgcolor="#EAEAEA" width="368" height="40"> Testing &amp; Commissioning Support</td>
              <td valign="middle" bgcolor="#EAEAEA" width="301" height="40"> Utilities</td>
            </tr>
            <tr>
              <td valign="middle" bgcolor="#FFFFFF" width="249" height="40"> Procurement Support</td>
              <td valign="middle" bgcolor="#FFFFFF" width="368" height="40"> Troubleshooting</td>
              <td valign="middle" bgcolor="#FFFFFF" width="301" height="40"> Safety Procedures Support</td>
            </tr>
            <tr>
              <td valign="middle" bgcolor="#EAEAEA" width="249" height="40"> Product Selection / Hook Up</td>
              <td valign="middle" bgcolor="#EAEAEA" width="368" height="40"> Optimization &amp; Stabilization</td>
              <td valign="middle" bgcolor="#EAEAEA" width="301" height="40"> Plant Audit</td>
            </tr>
            <tr>
              <td valign="middle" bgcolor="#FFFFFF" width="249" height="40"> Project Scheduling</td>
              <td valign="middle" bgcolor="#FFFFFF" width="368" height="40"> Project Engineering – Checking &amp; Validation</td>
              <td valign="middle" bgcolor="#FFFFFF" width="301" height="40"> Co-ordination with Civil / Mechanical</td>
            </tr>
            <tr>
              <td valign="middle" bgcolor="#EAEAEA" width="249" height="40"> Energy Management</td>
              <td valign="middle" bgcolor="#EAEAEA" width="368" height="40"> Energy Audit</td>
              <td valign="middle" bgcolor="#EAEAEA" width="301" height="40"> Energy Solutions</td>
            </tr>
            <tr>
              <td valign="middle" bgcolor="#FFFFFF" width="249" height="40"> Plant Electrics Estimation</td>
              <td valign="middle" bgcolor="#FFFFFF" width="368" height="40"> Plant Lighting</td>
              <td valign="middle" bgcolor="#FFFFFF" width="301" height="40"> Metro / Rly Electrics</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  );
}